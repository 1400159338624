<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Cliente" : "Cadastrar Cliente" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Principais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Nome Completo *</span>
              <v-text-field
                v-model="dados.nome"
                :rules="nameRules"
                required
                placeholder="Informe o Nome Completo"
                class="mt-n1 uppercase-text"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Tipo de Documento *</span>
              <v-select
                :items="items"
                item-value="id"
                item-text="descricao"
                v-model="dados.tipo_documento"
                :rules="fieldRules"
                placeholder="Informe o documento"
                class="mt-1 custom-solo-selection"
                solo
              />
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Documento *</span>
              <v-text-field
                v-model="dados.documento"
                :rules="fieldRules"
                v-mask-number
                required
                placeholder="Informe o número"
                class="mt-n1"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Pessoais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="4">
              <span class="primary--text">Data de Nascimento *</span>
              <v-text-field
                placeholder="Informe a data de nascimento"
                class="mt-n1"
                v-model="dados.data_nascimento"
                v-mask-date.br
                :rules="dataRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Gênero</span>
              <v-select
                placeholder="Informe o gênero"
                class="mt-1 custom-solo-selection"
                solo
                :items="['MASCULINO', 'FEMININO', 'OUTROS']"
                v-model="dados.sexo"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Estado Civil</span>
              <v-select
                placeholder="Informe o estado civil"
                class="mt-1 custom-solo-selection"
                solo
                :items="[
                  'SOLTEIRO',
                  'CASADO',
                  'DIVORCIADO',
                  'UNIÃO ESTÁVEL',
                  'VIÚVO',
                ]"
                v-model="dados.estado_civil"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Telefone</span>
              <v-text-field
                placeholder="Informe o telefone"
                class="mt-n1"
                v-model="dados.telefone"
                v-mask-phone.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Email *</span>
              <v-text-field
                placeholder="Informe o email"
                class="mt-n1"
                v-model="dados.email"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Profissão</span>
              <v-text-field
                placeholder="Informe a profissão"
                class="mt-n1"
                v-model="dados.profissao"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Instituição</span>
              <v-text-field
                placeholder="Informe a instituição"
                class="mt-n1"
                v-model="dados.instituicao"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Nome do Pai</span>
              <v-text-field
                placeholder="Informe o nome do pai"
                class="mt-n1"
                v-model="dados.nome_pai"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Nome da Mãe</span>
              <v-text-field
                placeholder="Informe o nome da mãe"
                class="mt-n1"
                v-model="dados.nome_mae"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações de Endereço</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="4">
              <span class="primary--text">CEP</span>
              <v-text-field
                placeholder="Informe o cep"
                class="mt-n1"
                v-model="dados.cep"
                @blur="getCep(dados.cep)"
                :loading="loading"
                v-mask-cep.br
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Logradouro</span>
              <v-text-field
                placeholder="Informe o logradouro"
                class="mt-n1"
                v-model="dados.logradouro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Número</span>
              <v-text-field
                placeholder="Informe o número"
                class="mt-n1"
                v-model="dados.numero"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Bairro</span>
              <v-text-field
                placeholder="Informe o bairro"
                class="mt-n1"
                v-model="dados.bairro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Cidade</span>
              <v-text-field
                placeholder="Informe a cidade"
                class="mt-n1"
                v-model="dados.cidade"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">UF</span>
              <v-text-field
                placeholder="UF"
                class="mt-n1"
                v-model="dados.uf"
                v-mask="'UU'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Complemento</span>
              <v-text-field
                placeholder="Informe o complemento"
                class="mt-n1"
                v-model="dados.complemento"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">País</span>
              <v-autocomplete
                :items="$store.getters.getPaises"
                v-model="dados.pais"
                autocomplete="off"
                placeholder="Informe o país"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Código IBGE</span>
              <v-text-field
                placeholder="Informe o código IBGE"
                class="mt-n1"
                v-model="dados.cibge"
                v-mask-number
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn depressed dark text :disabled="!valid" @click="validate">
          Confirmar
        </v-btn>
      </v-footer>
    </v-form>

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import BackArrow from "../../../components/BackArrow";
export default {
  components: {
    BackArrow,
  },
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        nome: "",
        tipo_documento: "",
        documento: "",
        data_nascimento: "",
        sexo: "",
        estado_civil: "",
        telefone: "",
        email: "",
        profissao: "",
        instituicao: "",
        nome_pai: "",
        nome_mae: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        pais: "",
        cibge: "",
      },
      items: [
        { id: "1", descricao: "RG" },
        { id: "2", descricao: "TÍTULO DE ELEITOR" },
        { id: "3", descricao: "PASSAPORTE" },
        { id: "4", descricao: "CNH" },
        { id: "5", descricao: "OUTROS" },
      ],
      tiposVenda: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      nameRules: [
        (v) => !!v || 'Este campo é requerido',
        (v) => (v.trim().split(' ').length >= 2) || 'Insira pelo menos o nome e o sobrenome'
      ],
      emailRules: [
        (v) => !!v || "E-mail é requerido",
        (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
      ],
      dataRules: [
        (v) => !!v || "Este campo é requerido",
        (v) =>
          /^\d{2}\/\d{2}\/\d{4}$/.test(v) ||
          "Insira uma data válida no formato DD/MM/AAAA!",
        (v) => {
          const [dia, mes, ano] = v.split("/").map(Number);
          const data = new Date(ano, mes - 1, dia);

          // Verifica se a data é válida
          if (
            data.getDate() !== dia ||
            data.getMonth() !== mes - 1 ||
            data.getFullYear() !== ano
          ) {
            return "Insira uma data válida!";
          }

          // Verifica se a data não é no futuro
          const hoje = new Date();
          if (data > hoje) {
            return "A data não pode ser no futuro!";
          }

          return true;
        },
      ],
    };
  },
  created() {
    this.loadTiposVenda();
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/clientes/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text = "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    loadTiposVenda() {
      this.$http
        .get(`/formaspagamento`)
        .then((r) => {
          this.tiposVenda = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    salvar() {
      this.dados.nome = this.dados.nome
        ? this.dados.nome.toUpperCase().trim()
        : this.dados.nome;
      this.dados.profissao = this.dados.profissao
        ? this.dados.profissao.toUpperCase().trim()
        : this.dados.profissao;
      this.dados.instituicao = this.dados.instituicao
        ? this.dados.instituicao.toUpperCase().trim()
        : this.dados.instituicao;
      this.dados.nome_pai = this.dados.nome_pai
        ? this.dados.nome_pai.toUpperCase().trim()
        : this.dados.nome_pai;
      this.dados.nome_mae = this.dados.nome_mae
        ? this.dados.nome_mae.toUpperCase().trim()
        : this.dados.nome_mae;
      this.dados.logradouro = this.dados.logradouro
        ? this.dados.logradouro.toUpperCase().trim()
        : this.dados.logradouro;
      this.dados.numero = this.dados.numero
        ? this.dados.numero.toUpperCase().trim()
        : this.dados.numero;
      this.dados.complemento = this.dados.complemento
        ? this.dados.complemento.toUpperCase().trim()
        : this.dados.complemento;
      this.dados.bairro = this.dados.bairro
        ? this.dados.bairro.toUpperCase().trim()
        : this.dados.bairro;
      this.dados.cidade = this.dados.cidade
        ? this.dados.cidade.toUpperCase().trim()
        : this.dados.cidade;
      this.dados.uf = this.dados.uf
        ? this.dados.uf.toUpperCase().trim()
        : this.dados.uf;

      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/clientes/update/${this.id}`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/clientes");
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/clientes/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/clientes");
              }, 1500);
            } else {
              this.message.text = r.data.warning;
              this.message.snacshow = true;
              this.message.type = "warning";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    getCep(cep) {
      if (cep.length == 10) {
        this.loading = true;
        cep = cep.replace("-", "");
        cep = cep.replace(".", "");
        axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            this.dados.logradouro = response.data.logradouro;
            this.dados.bairro = response.data.bairro;
            this.dados.cidade = response.data.localidade;
            this.dados.uf = response.data.uf;
            this.dados.cibge = response.data.ibge;
            this.loading = false;
          })
          .catch((e) => {
            this.message.snacshow = true;
            this.message.type = "warning";
            this.message.text = "Atencão cep não encontrado!" + e;
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style src="../style.vue"></style>
